const activitiesData = [
	{
		tabTitle: 'Find Your Style',
		subtitle: 'Explore the aesthetics',
		title: 'Discover Timeless Designs',
		img: 'assets/img/home-page/activities/living-room.jpeg',
		content:
			"From sleek modern aesthetics to warm traditional charm, our design inspirations help you discover the style that speaks to you. Each piece is thoughtfully chosen to blend seamlessly into your space, offering a curated selection that reflects timeless elegance and contemporary appeal. Whether you're looking to refresh a single room or redesign your entire home, explore ideas that transform spaces into places you love.",
		buttonTitle: 'Learn More',
		buttonLink: '/about',
	},

	{
		tabTitle: 'Built to Last',
		subtitle: 'Furniture crafted with care',
		title: 'Expert Craftsmanship, Enduring Quality',
		img: 'assets/img/home-page/activities/craftmentship.jpeg',
		content:
			'Craftsmanship is at the heart of our furniture collection, showcasing meticulous attention to detail and dedication to quality. Each piece is shaped by skilled artisans who bring passion and precision to their work, ensuring furniture that’s as enduring as it is beautiful. With fine finishes and a focus on durability, our collections represent a blend of artistry and functionality that transforms any space.',
		buttonTitle: 'Get the Latest Research',
		buttonLink: '/about',
	},
	{
		tabTitle: 'Eco-Friendly Choices',
		subtitle: 'Furniture that respects the planet.',
		title: 'Sustainable Materials, Lasting Impact',
		img: 'assets/img/home-page/activities/lounge.jpeg',
		content:
			'Our commitment to sustainability is reflected in every piece, from responsibly sourced materials to eco-conscious designs that stand the test of time. By choosing furniture crafted with care for the environment, you bring home more than just style—you contribute to a lasting, positive impact. Discover options that combine quality with environmental responsibility, offering beauty and sustainability in equal measure.',
		buttonTitle: 'Discover Our Technology',
		buttonLink: '/about',
	},
];

export default activitiesData;
