import {motion, useScroll, useTransform, useSpring} from "framer-motion";
import React from "react";
import {Image} from "react-bootstrap";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";

export const HeroParallax = ({
                               products
                             }) => {
  const firstRow = products.slice(0, 5);
  const secondRow = products.slice(5, 10);
  const thirdRow = products.slice(10, 15);
  const ref = React.useRef(null);
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const springConfig = {stiffness: 300, damping: 30, bounce: 100};

  const translateX = useSpring(useTransform(scrollYProgress, [0, 1], [0, 1000]), springConfig);
  const translateXReverse = useSpring(useTransform(scrollYProgress, [0, 1], [0, -1000]), springConfig);
  const rotateX = useSpring(useTransform(scrollYProgress, [0, 0.2], [15, 0]), springConfig);
  const opacity = useSpring(useTransform(scrollYProgress, [0, 0.1], [0.1, 1]), springConfig);
  const rotateZ = useSpring(useTransform(scrollYProgress, [0, 0.2], [20, 0]), springConfig);
  const translateY = useSpring(useTransform(scrollYProgress, [0, 0.2], [-700, 500]), springConfig);
  return (
    (<div
      ref={ref}
      className="sm:h-[1800px] h-[2100px] sm:py-4 py-40 overflow-hidden antialiased relative flex flex-col self-auto [perspective:1000px] [transform-style:preserve-3d] transform-gpu">
      <Header/>
      <motion.div
        style={{
          rotateX,
          rotateZ,
          translateY,
          opacity,
        }}
        className="">
        <motion.div className="flex flex-row-reverse space-x-reverse space-x-20 sm:space-x-1 mb-20 sm:mb-12">
          {firstRow.map((img, idx) => (
            <ProductCard img={img} translate={translateX} key={idx}/>
          ))}
        </motion.div>
        <motion.div className="flex flex-row mb-20 sm:mb-12 space-x-20 sm:space-x-1">
          {secondRow.map((img, idx) => (
            <ProductCard img={img} translate={translateXReverse} key={idx}/>
          ))}
        </motion.div>
        <motion.div className="flex flex-row-reverse space-x-reverse space-x-20 sm:space-x-1">
          {thirdRow.map((img, idx) => (
            <ProductCard img={img} translate={translateX} key={idx}/>
          ))}
        </motion.div>
      </motion.div>
    </div>)
  );
};

export const Header = () => {
  return (
    (<div
      className="max-w-7xl relative mx-auto py-20 md:py-40 px-4 w-full left-0 top-0">
      <h2 className="text-4xl md:text-5xl font-bold text-tprimary">
        Designed for You, Crafted with Care
      </h2>
      <p className="max-w-4xl text-base md:text-xl mt-8 text-ttertiary">
        Let’s come together to craft spaces that inspire, elevate, and bring out the best in each of us—a place where
        creativity thrives, connections are strengthened, and every detail reflects a shared vision of beauty and
        purpose.
      </p>
    </div>)
  );
};

export const ProductCard = ({
                              img, translate
                            }) => {
  const {isMobile} = useResponsiveScreen();
  return (
    (<motion.div
      style={{
        x: translate,
      }}
      whileHover={{
        y: -20,
      }}
      key={img}
      className="group/product h-96 w-[30rem] relative flex-shrink-0">
      <Image
        src={img}
        height={isMobile ? "300" : "600"}
        width={isMobile ? "300" : "600"}
        className="object-cover object-left-top absolute h-full rounded-lg inset-0 w-[600]"
      />
    </motion.div>)
  );
};