import Lottie from 'react-lottie';
import animationData from './loading.json';
import useResponsiveScreen from '../../../hooks/useResponsiveScreen';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading = () => {
  const {isMobile} = useResponsiveScreen();
  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50'>
      <div className='absolute top-0 left-0 right-0 bottom-0 bg-tsecondary'></div>
      <Lottie
        options={defaultOptions}
        height={isMobile ? 300 : 400}
        width={isMobile ? 300 : 400}
      />
    </div>
  );
};

export default Loading;
