import {Col, Container, Row} from 'react-bootstrap';
import {Footer as LithoFooter} from '../../Litho/Components/Footers/Footer';
import useResponsiveScreen from "../../hooks/useResponsiveScreen";

const Footer = () => {
  const {isMobile} = useResponsiveScreen();
  return (
    <LithoFooter
      parallax={{desktop: false, lg: false}}
      className={`pt-[5.5rem] pb-[3.5rem] md:py-[8.5rem] bg-zinc-900 footer-style-11`}
      style={{
        backgroundImage: "url('/assets/img/home-page/dots-pattern.png')",
      }}
    >
      <Container>
        <Row className='flex justify-center'>
          <Col
            lg={8}
            className='text-center flex flex-col gap-3 justify-center'
          >
            <div className='flex flex-col items-center gap-4'>
              <img
                width={isMobile ? "140" : '190'}
                height={isMobile ? "30" : '36'}
                loading='lazy'
                src='/assets/img/mcpl-logo.png'
                alt='logo'
                className='default-logo cursor-pointer'
                onClick={() => window.location.replace('/')}
              />
              <h4 className='font-serif font-light mb-[10px] block text-lg sm:text-md text-ttertiary'>
                Transforming spaces with timeless furniture that blends comfort,
                quality, and style.
              </h4>
            </div>
            <h4 className='font-serif font-semibold text-[2.25rem] sm:text-lg leading-[3.4rem]'>
              <a
                aria-label='mail'
                rel='noreferrer'
                className='border-none md:mb-[15px] no-underline'
                href='mailto:info@domain.com'
              >
								<span className='text-ttertiary hover:text-tprimary transition-colors duration-300'>
									info@mcpl.com
								</span>
              </a>
            </h4>
          </Col>
        </Row>
      </Container>
    </LithoFooter>
  );
};

export default Footer;
