import {Col, Container, Row} from 'react-bootstrap';
import {m} from 'framer-motion';
import {
  fadeIn,
  fadeInLeft,
  fadeInRight,
} from '../../../Litho/Functions/GlobalAnimations';
import useResponsiveScreen from '../../../hooks/useResponsiveScreen';

const MarketExpertiseSection = () => {
	const { isMobile } = useResponsiveScreen();
	return (
		<m.section
			className='pt-[130px] lg:pt-[90px] md:pt-[75px] xs:pt-[50px] overflow-hidden'
			{...fadeIn}
		>
			<Container>
				<Row className='items-center'>
					<Col xl={5} lg={6} className='pb-12 lg:pb-28 sm:pb-[50px]'>
						<m.div
							{...{
								...fadeInRight,
								transition: { duration: 0.9 },
								viewport: { once: false },
							}}
						>
							<div className='text-xmd text-ttertiary mb-[20px] font-serif'>
								Craftsmanship and Design Excellence
							</div>
							<h6 className='font-serif text-tprimary font-medium mb-[5.5rem]'>
								Our Expertise
							</h6>
						</m.div>
						<Row>
							{isMobile && (
								<Col
									lg={6}
									xl={{ offset: 1 }}
									className='align-self-center text-center text-xl-start mb-20'
								>
									<m.img
										className='md:mx-auto'
										loading='lazy'
										src='/assets/img/contact-us-page/wood.png'
										width='504'
										height='666'
										alt='market-expert'
										{...{
											...fadeInLeft,
											transition: { duration: 0.7, delay: 0.2 },
											viewport: { once: false },
										}}
									/>
								</Col>
							)}
							<Col>
								<m.div
									className='flex'
									{...{
										...fadeInRight,
										transition: { duration: 0.6, delay: 0.2 },
										viewport: { once: false },
									}}
								>
									<div className='mr-[35px]'>
										<i className='fa-solid fa-hands text-[50px] text-tprimary'></i>
									</div>
									<div className='feature-box-content '>
										<span className='text-tprimary block mb-[10px] font-serif font-medium'>
											Exceptional Craftsmanship
										</span>
										<p className='text-ttertiary w-[95%]'>
											Every piece is thoughtfully designed and carefully crafted
											to stand the test of time, blending quality with beauty in
											every detail.
										</p>
									</div>
								</m.div>
							</Col>
							<Col className='col-12'>
								<m.div
									className='h-[1px] bg-[#ededed] my-[40px] w-full xs:my-[30px]'
									{...{
										...fadeIn,
										transition: { duration: 0.7, delay: 0.2 },
										viewport: { once: false },
									}}
								></m.div>
							</Col>
							<Col>
								<m.div
									className='flex'
									{...{
										...fadeInRight,
										transition: { duration: 0.7, delay: 0.4 },
										viewport: { once: false },
									}}
								>
									<div className='mr-[35px]'>
										<i className='fa-solid fa-palette text-[50px] text-tprimary'></i>
									</div>
									<div className='feature-box-content '>
										<span className='text-tprimary block mb-[10px] font-serif font-medium'>
											Timeless Design
										</span>
										<p className='text-ttertiary w-[95%]'>
											Our designs capture both current trends and classic
											styles, creating furniture that complements any space with
											elegance and charm.
										</p>
									</div>
								</m.div>
							</Col>
						</Row>
					</Col>
					{!isMobile && (
						<Col
							lg={6}
							xl={{ offset: 1 }}
							className='align-self-center text-center text-xl-start'
						>
							<m.img
								className='md:mx-auto'
								loading='lazy'
								src='/assets/img/contact-us-page/wood.png'
								width='504'
								height='666'
								alt='market-expert'
								{...{
									...fadeInLeft,
									transition: { duration: 0.7, delay: 0.2 },
									viewport: { once: false },
								}}
							/>
						</Col>
					)}
				</Row>
			</Container>
		</m.section>
	);
};

export default MarketExpertiseSection;
