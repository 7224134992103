import { Helmet } from 'react-helmet-async';

const ContactPageHelmet = () => {
	return (
		<Helmet>
			<title>Contact Us</title>
			<meta
				name='description'
				content='Contact us at Multilines for expert guidance on quality furniture, from elegant sofas to stylish tables. Let us help bring your space to life with pieces crafted for comfort and durability.'
			/>

			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.mcpl.pk/contact' />
			<meta
				property='og:title'
				content='Contact Multilines | Quality Furniture for Every Space'
			/>

			<meta
				property='og:image'
				content='https://www.mcpl.pk/assets/img/mcpl-logo.png'
			/>
			<meta
				property='og:description'
				content='Connect with Multilines for beautifully crafted furniture. From sofas to tables, our team is here to help you create spaces that feel like home.'
			/>

			<meta property='og:site_name' content='Multilines Corporation' />
			<meta property='og:locale' content='en_US' />
		</Helmet>
	);
};

export default ContactPageHelmet;
