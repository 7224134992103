import {Col, Container, Row} from 'react-bootstrap';
import TextBox from './TextBox';
import {
  fadeInLeft,
  fadeInRight,
} from '../../../Litho/Functions/GlobalAnimations';
import {m} from 'framer-motion';

const TextBoxData = [
  {
    number: '01',
    title: 'Vision',
    description:
      "Our vision is to create spaces that inspire and bring out the best in every individual, where every detail is a reflection of beauty and purpose.",
  },
  {
    number: '02',
    title: 'Approach',
    description:
      'We believe in a collaborative approach, where your ideas and our expertise come together to shape environments that feel truly personal and unique.',
  },
];

const OurJourney = () => {
  return (
		<section className='px-[25px] pt-[100px] pb-[130px] overflow-hidden lg:py-[90px] sm:py-[70px] sm:pb-0 xs:py-[50px] xs:px-0 bg-tsecondary'>
			<Container>
				<Row className='items-center justify-center'>
					<Col
						lg={4}
						sm={8}
						className='md:text-center text-lg-start md:mb-[50px]'
					>
						<m.h6
							className='font-serif sm:text-3xl w-[80%] text-tprimary font-medium mb-[20px] lg:w-[90%] md:w-full md:mb-[10px]'
							{...{
								...fadeInRight,
								transition: { duration: 0.9 },
								viewport: { once: false },
							}}
						>
							Crafting Unique Spaces, Together
						</m.h6>
					</Col>
					<TextBox
						grid='row-cols-1 row-cols-md-2 col-lg-8 sm:gap-5 md:gap-0'
						theme='text-box-style-02 flex-wrap lg:px-0'
						className='sm:mb-[30px] xs:last:mb-0'
						data={TextBoxData}
						{...{
							fadeInLeft,
							transition: { duration: 0.9 },
							viewport: { once: false },
						}}
					/>
				</Row>
			</Container>
		</section>
	);
};

export default OurJourney;
