const productsData = [
	{
		title: 'Snooker Table',
		titleClassName: 'text-snooker',
		gridItems: [
			{
				src: '/assets/img/products-page/snooker/1-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/snooker/2.jpg',
				minSrc: '/assets/img/products-page/snooker/2-small.jpg',
				width: 3,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/snooker/3-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/snooker/4-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/snooker/5-small.jpg',
				className: 'w-full',
				width: 2,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/snooker/6-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
		],
	},
	{
		title: 'Doors',
		titleClassName: 'text-[#895f40]',
		gridItems: [
			{
				src: '/assets/img/products-page/doors/1-small.jpg',
				className: 'h-full',
				width: 1,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/2.jpg',
				minSrc: '/assets/img/products-page/doors/2-small.jpg',
				className: 'h-full',
				width: 1,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/3-small.jpg',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/4-small.jpg',
				className: 'h-full',
				width: 1,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/5.jpg',
				minSrc: '/assets/img/products-page/doors/5-small.jpg',
				className: 'h-full',
				width: 1,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/6-small.jpg',
				className: 'w-full',
				width: 1,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/7-small.jpg',
				className: 'w-full',
				width: 1,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/doors/8-small.jpg',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
		],
	},
	{
		title: 'Beds',
		titleClassName: 'text-[#895f40]',
		gridItems: [
			{
				src: '/assets/img/products-page/beds/1.webp',
				minSrc: '/assets/img/products-page/beds/1-small.jpg',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/beds/2.jpeg',
				minSrc: '/assets/img/products-page/beds/2-small.jpg',
				className: 'h-full',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/beds/3.jpg',
				minSrc: '/assets/img/products-page/beds/3-small.jpg',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/beds/4.webp',
				minSrc: '/assets/img/products-page/beds/4-small.jpg',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/beds/5.webp',
				minSrc: '/assets/img/products-page/beds/5-small.jpg',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/beds/6.jpg',
				minSrc: '/assets/img/products-page/beds/6-small.jpg',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
		],
	},
	{
		title: 'Sofas',
		titleClassName: 'text-[#895f40]',
		gridItems: [
			{
				src: '/assets/img/products-page/sofas/1.jpg',
				width: 2,
				height: 6,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/sofas/2.webp',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/sofas/3.webp',
				width: 2,
				height: 3,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
		],
	},
	{
		title: 'Chairs',
		titleClassName: 'text-[#895f40]',
		gridItems: [
			{
				src: '/assets/img/products-page/tables/1-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/tables/2.jpg',
				minSrc: '/assets/img/products-page/tables/2-small.jpg',
				width: 3,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/tables/3-small.jpg',
				className: 'h-full',
				width: 1,
				height: 2,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/tables/4.jpg',
				minSrc: '/assets/img/products-page/tables/4-small.jpg',
				className: 'h-full',
				width: 3,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
			{
				src: '/assets/img/products-page/tables/5.jpg',
				minSrc: '/assets/img/products-page/tables/5-small.jpg',
				className: 'h-full',
				width: 1,
				height: 4,
				mobileClassName: 'h-full w-full',
				mobileWidth: 2,
				mobileHeight: 2,
			},
		],
	},
];

export default productsData;
