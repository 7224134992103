import {Container} from 'react-bootstrap';
import colors from '../../../constants/colors';
import Buttons from '../../../Litho/Components/Button/Buttons';
import CommonProductsSection from './../../sections/CommonProductsSection';
import useResponsiveScreen from '../../../hooks/useResponsiveScreen';
import { Element } from 'react-scroll';
import productsData from './../../sections/CommonProductsSection/productsData';

const ProductsSection = () => {
  const {isMobile} = useResponsiveScreen();
  return (
    <Element
      name='products'
    >
      <div
        style={{
          backgroundImage: "url('/assets/img/home-page/box-pattern.png')",
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
        }}
      >
        <Container
          className='flex flex-col items-center gap-20 py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]'>
          <CommonProductsSection productsData={productsData}/>
          <Buttons
            ariaLabel='tab button'
            className='btn-flat rounded-full font-medium font-serif text-tprimary hover:!text-white tracking-[1px] uppercase md:mb-[15px] xs:mb-0 xs:mt-[20px]'
            size={isMobile ? 'md' : 'xl'}
            themeColor={colors.tprimary}
            to={'/contact'}
            title='Contact Us'
          />
        </Container>
      </div>
    </Element>
  );
};

export default ProductsSection;
