import {useMemo} from 'react';

import {BentoGrid as Grid} from 'react-bento';
import {Image} from 'react-bootstrap';
import useResponsiveScreen from './../../../hooks/useResponsiveScreen';

const BentoGrid = ({bentoItems}) => {
  const {isMobile} = useResponsiveScreen();

  const data = useMemo(() => {
    return bentoItems?.map((item, idx) => {
      return {
				id: idx,
				element: (
					<Image
						src={item?.src}
						className={
							isMobile
								? item?.mobileClassName
								: item?.className +
								  ' hover:scale-105 duration-300 hover:shadow-2xl transition-transform'
						}
					/>
				),
				width: isMobile ? item?.mobileWidth : item.width,
				height: isMobile ? item?.mobileHeight : item.height,
			};
    });
  }, [bentoItems, isMobile]);

  return (
    <Grid
      items={data || []}
      gridCols={4}
      rowHeight={100}
      classNames={{
        container: 'my-custom-container-class p-0',
        elementContainer: 'my-custom-element-class p-0 bg-transparent',
      }}
    />
  );
};

export default BentoGrid;
