import { m } from 'framer-motion';
import { fadeIn } from '../../../Litho/Functions/GlobalAnimations';
import { Container } from 'react-bootstrap';
import Tab01 from '../../../Litho/Components/Tab/Tab01';

import activitiesData from './activitiesData';
import { Element } from 'react-scroll';

const ActivitiesSection = () => {
	return (
		<Element name='activities'>
			<m.section
				className='overflow-x-hidden py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]'
				{...fadeIn}
			>
				<Container>
					<Tab01 data={activitiesData} />
				</Container>
			</m.section>
		</Element>
	);
};

export default ActivitiesSection;
