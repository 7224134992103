import BentoGrid from '../../basic/BentoGrid';

const CommonProductsSection = ({ productsData }) => {
	return (
		<div className='overflow-hidden'>
			<h2 className='text-center text-4xl font-bold text-tprimary uppercase mb-16 object-fit-cover'>
				Our Luxury Products
			</h2>
			<div className='flex flex-col gap-20'>
				{productsData?.map((product) => (
					<div>
						<h4
							className={`text-4xl font-bold mb-4 ${product?.titleClassName}`}
						>
							{product?.title}
						</h4>
						<BentoGrid bentoItems={product?.gridItems} />
					</div>
				))}
			</div>
		</div>
	);
};

export default CommonProductsSection;
