import { Helmet } from 'react-helmet-async';

const ProductsPageHelmet = () => {
	return (
		<Helmet>
			<title>Our Furniture Collection</title>
			<meta
				name='description'
				content='Discover our curated selection of high-quality furniture, including sofas, tables, and more. Crafted for comfort, durability, and timeless style.'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.mcpl.pk/products' />
			<meta
				property='og:title'
				content='Multilines | Quality Furniture for Every Room'
			/>
			<meta
				property='og:image'
				content='https://www.mcpl.pk/assets/img/furniture-collection.png'
			/>
			<meta
				property='og:description'
				content='Explore beautifully crafted furniture that enhances any space. From sofas to tables, find pieces that fit your style and comfort needs.'
			/>
			<meta property='og:site_name' content='Multilines' />
			<meta property='og:locale' content='en_US' />
		</Helmet>
	);
};

export default ProductsPageHelmet;
