import {Col, Container, Image, Row} from 'react-bootstrap';
import {Link as ScrollTo} from "react-scroll";

const HeroSection = () => {
  return (
		<div
			className='overflow-hidden relative'
			style={{
				backgroundImage: "url('/assets/img/home-page/box-pattern.png')",
				backgroundRepeat: 'repeat',
				// backgroundPosition: 'center',
				backgroundPosition: 'top 10px',
			}}
		>
			<Container className='relative'>
				<Row className='items-center justify-between pt-20 sm:!pt-16 h-[calc(100vh-75px)] md:h-[calc(650px-80px)] sm:h-[calc(550px-80px)]'>
					<Col lg={5} md={6} sm={8}>
						<span className='text-xmd font-serif font-medium uppercase text-tprimary block mb-[35px] xs:mb-[15px]'>
							Trusted services, built for you.
						</span>
						<h3 className='font-roboto font-bold text-tprimary !text-3xl uppercase mb-[2.5rem] sm:mb-[20px] xs:mb-[15px]'>
							Our Expertise at Your Service
						</h3>
						<p className='text-xmd text-ttertiary leading-[30px] w-[85%] mb-12 lg:w-[90%] md:w-full xs:mb-[30px]'>
							Offering trusted expertise in gold trading, ensuring precision and
							enduring value in every transaction.
						</p>
					</Col>

					<Col lg={5} md={5} sm={4} className=''>
						<Image
							src='/assets/img/contact-us-page/wood.png'
							alt='gold'
							className='object-cover sm:hidden flex'
							width={570}
						/>
					</Col>
				</Row>
				<ScrollTo
					to='products'
					offset={100}
					delay={0}
					spy={true}
					smooth={true}
					duration={800}
					className='w-full absolute bottom-[0px] left-0 right-0 z-[1] flex justify-center items-center xs:hidden cursor-pointer'
				>
					<i className='fas fa-arrow-down text-lg text-tsecondary bg-tprimary shadow-[0_0_25px_rgba(0,0,0,0.08)] w-[45px] h-[45px] leading-[46px] rounded-full flex justify-center items-center'></i>
				</ScrollTo>
			</Container>
		</div>
	);
};

export default HeroSection;
