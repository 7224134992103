import { Col, Container, Row } from 'react-bootstrap';
import { m } from 'framer-motion';
import * as animationData from './contact.json';
import Lottie from 'react-lottie';

const HeroSection = () => {

	const defaultOptions = {
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<m.section
			className='py-[100px] lg:py-[90px] md:py-[75px] sm:py-[50px]'
			style={{
				backgroundImage: 'url(assets/img/home-page/line-pattern.png)',
				backgroundSize: 'cover',
			}}
		>
			<Container className='h-[300px] sm:h-[250px] flex justify-center sm:justify-end'>
				<Row xs={1} md={1} className='items-end justify-end sm:flex-col'>
				<Col
					className='items-end justify-end sm:flex-col'
				>

					<Lottie

						options={defaultOptions}
						height={200}
						speed={0.5}

					/>
				</Col>
					<Col className='p-0 sm:!px-[15px]' colSpan={2}>
						<h4 className='font-medium text-tprimary font-serif mb-0 w-full'>
							Let’s build spaces that inspire together.
						</h4>
					</Col>
				</Row>
			</Container>
		</m.section>
	);
};

export default HeroSection;
