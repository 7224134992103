import PageWrapper from '../components/basic/PageWrapper';
import OurJourney from '../components/components/AboutUsPage/OurJourney';
import MissionAndValuesSection from '../components/components/AboutUsPage/MissionAndValuesSection';
import MarketExpertiseSection from '../components/components/AboutUsPage/MarketExpertiesSection';
import AboutPageHelmet from '../components/components/AboutUsPage/AboutPageHelmet';
import React from 'react';
import { HeroParallax } from '../components/basic/HeroParallax';
import productsData from '../components/sections/CommonProductsSection/productsData';

const AboutUsPage = () => {
	return (
		<>
			<AboutPageHelmet />
			<PageWrapper>
				<div>
					<HeroParallax
						products={productsData
							.map((product) => product.gridItems)
							.flat()
							.map((product) => product.minSrc || product?.src)}
					/>
				</div>
				<div>
					<OurJourney />
					<MissionAndValuesSection />
					<MarketExpertiseSection />
				</div>
			</PageWrapper>
		</>
	);
};

export default AboutUsPage;
