import { Helmet } from 'react-helmet-async';

const AboutPageHelmet = () => {
	return (
		<Helmet>
			<title>About Us</title>
			<meta
				name='description'
				content='At Multilines, we are committed to crafting furniture that blends quality, style, and comfort to elevate your space.'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.mcpl.pk/about' />
			<meta property='og:title' content='About Us - Multilines Furniture' />
			<meta
				property='og:image'
				content='https://www.mcpl.pk/assets/img/furniture-about.png'
			/>
			<meta
				property='og:description'
				content='Discover our story and commitment to crafting quality furniture designed to bring comfort and style to every room.'
			/>
			<meta property='og:site_name' content='Multilines Furniture' />
			<meta property='og:locale' content='en_US' />
			<link rel='canonical' href='https://www.mcpl.pk/about' />
		</Helmet>
	);
};

export default AboutPageHelmet;
