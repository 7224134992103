import PageWrapper from '../components/basic/PageWrapper';
import {m} from 'framer-motion';
import {fadeIn} from '../Litho/Functions/GlobalAnimations';
import GoogleMap from '../Litho/Components/GoogleMap/GoogleMap';
import HeroSection from '../components/components/ContactUsPage/HeroSection';
import AddressSection from '../components/components/ContactUsPage/AddressSection';
import ContactForm from '../components/components/ContactUsPage/ContactForm';
import ContactPageHelmet from '../components/components/ContactUsPage/ContactPageHelmet';

const ContactUsPage = () => {
  return (
    <>
      <ContactPageHelmet/>
      <PageWrapper>
        <HeroSection/>

        <AddressSection/>

        <ContactForm/>

        <m.section {...fadeIn} id='google-map-location'>
          <GoogleMap
            className='h-[500px] xs:h-[300px]'
            location="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d970.941225132729!2d74.43748573918258!3d31.18055630258541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1731047297128!5m2!1sen!2s"
          />
        </m.section>
      </PageWrapper>
    </>
  );
};

export default ContactUsPage;
