import { Helmet } from 'react-helmet-async';

const HomePageHelmet = () => {
	return (
		<Helmet>
			<title>Multilines Corporation </title>
			<meta content='Crafting spaces that feel like home. Discover timeless furniture pieces designed to elevate comfort, style, and quality in every corner of your space.' />
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://www.mcpl.pk' />
			<meta
				property='og:title'
				content='Multilines - Quality Furniture Crafted for Every Home'
			/>

			<meta
				property='og:image'
				content='https://www.mcpl.pk/assets/img/mcpl-logo.png'
			/>
			<meta
				property='og:description'
				content='Crafting spaces that feel like home. Discover timeless furniture pieces designed to elevate comfort, style, and quality in every corner of your space.'
			/>
			<meta
				name='keywords'
				content='furniture, quality furniture, sofas, tables, home decor, living room furniture, bedroom furniture, crafted furniture, Multilines, home furniture, buy furniture online, mcpl, mcpl.pk, multilines pakistan, multilines corporation'
			/>

			<meta
				name='description'
				content='Crafting spaces that feel like home. Discover timeless furniture pieces designed to elevate comfort, style, and quality in every corner of your space.'
			/>
			<meta
				property='og:site_name'
				content='Multilines Corporation Private Limited'
			/>
			<meta property='og:locale' content='en_US' />
			<link rel='canonical' href='https://www.mcpl.pk' />
		</Helmet>
	);
};

export default HomePageHelmet;
